import {
  IExtraTransactionData
} from "3-widgets/transaction/TransactionList";
import {apiBaseUrl} from "../../config";
import {processTransactions} from "./pnlDatesMerge";

export const fetchTransactionsExtraData = async (transactionIds: string[]): Promise<IExtraTransactionData[]> => {
  const chunkSize = 50;

  const chunkArray = (array: string[], size: number): string[][] => {
    const results = [];
    for (let i = 0; i < array.length; i += size) {
      results.push(array.slice(i, i + size));
    }
    return results;
  };

  const transactionChunks = chunkArray(transactionIds, chunkSize);

  try {
    const allResults = await Promise.allSettled(
      transactionChunks.map(async (chunk) => {
        const response = await fetch(`${apiBaseUrl}/load-extras`, {
          method: 'POST',
          body: JSON.stringify({ transactionIds: chunk }),
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error(`Network response was not ok: ${response.statusText}`);
        }

        return await response.json();
      })
    );
    return allResults
      .filter((result): result is PromiseFulfilledResult<IExtraTransactionData[]> => result.status === 'fulfilled' && result.value !== null)
      .flatMap(result => result.value);

  } catch (error) {
    console.error('Failed to populate transactions with Rocket data:', error);
    throw error;
  }
};

export const collectStructureTransactionsIds = async (operations:any) => {
  let transactionsIds =[]

  for (const [date, entry] of Object.entries(operations)) {
    //@ts-ignore
    for (const [categoryId, category] of Object.entries(entry)) {
      //@ts-ignore
      transactionsIds.push(category.totalTransactions.map(tr => tr.id))
    }
  }
  const uniqueTransactionsIds =  [...new Set(transactionsIds.flat())];
  return await fetchTransactionsExtraData(uniqueTransactionsIds)
}


