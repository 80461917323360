export function isoMonth(date: any) {
  return date.slice(0, 7);
}

// function findTransaction(allTransactions, transactionId) {
//   for (const [month, transactions] of allTransactions) {
//     for (const tagId in transactions) {
//       const tag = transactions[tagId];
//
//       const foundInSelf = tag.selfTransactions.find(tr => tr.id === transactionId);
//       const foundInChildren = tag.childrenTransactions.find(tr => tr.id === transactionId);
//       const foundInTotal = tag.totalTransactions.find(tr => tr.id === transactionId);
//
//       if (foundInSelf || foundInChildren || foundInTotal) {
//         return {
//           month,
//           tagId,
//           foundInSelf,
//           foundInChildren,
//           foundInTotal,
//         };
//       }
//     }
//   }
//
//   return null;
// }

// function cloneMonthStructure(sourceMonth) {
//   return JSON.parse(JSON.stringify(sourceMonth));
// }

function moveTransaction(allTransactions: any[], currentPeriod: any, tagId: string, tagData: any, transactionType: string, transaction: {
  id: any;
  date: string | number | Date;
}, targetMonth: string) {
  // Найти период с нужной датой (год и месяц)
  let targetPeriod = allTransactions.find(periodData => periodData[0] === targetMonth);

  if (!targetPeriod) {
    // Если такого периода нет, создаём копию текущего периода
    const newPeriodData = JSON.parse(JSON.stringify([targetMonth, {}]));
    allTransactions.push(newPeriodData);
    targetPeriod = newPeriodData;
  }

  // Переместить транзакцию в новый период
  const targetTransactionsByTags = targetPeriod[1];
  if (!targetTransactionsByTags[tagId]) {
    targetTransactionsByTags[tagId] = JSON.parse(JSON.stringify(tagData));
    // Очищаем массивы транзакций для нового периода
    targetTransactionsByTags[tagId].totalTransactions = [];
    targetTransactionsByTags[tagId].selfTransactions = [];
    targetTransactionsByTags[tagId].childrenTransactions = [];
  }

  targetTransactionsByTags[tagId][transactionType].push(transaction);
}

export function processTransactions(allTransactions:any, extraData:any) {
  extraData.forEach((item: { transaction_id: any; pl_date: string | number | Date; }) => {
    const transactionId = item.transaction_id;
    const plDate = item.pl_date ? new Date(item?.pl_date) : null;

    if (!plDate) {
      return; // Пропускаем, если pl_date пуст
    }

    const plMonth = plDate.getUTCFullYear() + "-" + ("0" + (plDate.getUTCMonth() + 1)).slice(-2);

    allTransactions.forEach((periodData: [any, any]) => {
      const [period, transactionsByTags] = periodData;

      Object.keys(transactionsByTags).forEach(tagId => {
        const tagData = transactionsByTags[tagId];
        const allTransactionTypes = ['totalTransactions', 'selfTransactions', 'childrenTransactions'];

        allTransactionTypes.forEach(type => {
          if (tagData[type]) {
            tagData[type] = tagData[type].filter((transaction: { id: any; date: string | number | Date; }) => {
              if (transaction.id === transactionId) {
                const transactionDate = new Date(transaction.date);
                const transactionMonth = transactionDate.getUTCFullYear() + "-" + ("0" + (transactionDate.getUTCMonth() + 1)).slice(-2);

                if (transactionMonth !== plMonth) {
                  moveTransaction(allTransactions, period, tagId, tagData, type, transaction, plMonth);
                  return false; // Удалить транзакцию из текущего массива
                }
              }
              return true; // Оставить транзакцию
            });
          }
        });
      });
    });
  })
  return allTransactions; // Возвращаем обновленный массив
}

