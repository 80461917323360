import React, { FC } from 'react'
import { Typography, Box, Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ChevronDownIcon } from '6-shared/ui/Icons'
import {TDateDraft, TISOMonth} from '6-shared/types'

import { TableRow, useIsSmall } from '../shared/shared'
import { useColumns } from '../models/useMetric'
import { useTableMenu1, TableMenu1 } from './TableMenu1'
import MonthMultiSelect from "./MonthMultiSelect";
import {formatDate} from "../../../../6-shared/helpers/date";

type HeaderProps = {
  columns: TISOMonth[];
  isAllShown: boolean
  isReordering: boolean
  onShowAllToggle: () => void
  onReorderModeToggle: () => void
  onOpenOverview: () => void
  onMonthsChange: (selectedMonths: TISOMonth[]) => void;
}

export const Header: FC<HeaderProps> = props => {
  const {
    columns,
    isAllShown,
    isReordering,
    onShowAllToggle,
    onReorderModeToggle,
    onOpenOverview,
    onMonthsChange
  } = props
  const { t } = useTranslation('common')
  const isSmall = useIsSmall()
  const openOnClick = useTableMenu1({
    isAllShown,
    isReordering,
    onShowAllToggle,
    onReorderModeToggle,
  })

  const { nextColumn } = useColumns()
  const ColumnTitle: FC<{ name: string }> = props => (
    <Typography
      variant="overline"
      color="text.secondary"
      align="center"
      onClick={nextColumn}
      noWrap
    >
      {props.name}
    </Typography>
  )

  function getMonthName(month: TDateDraft) {
    return formatDate(month, 'LLL').toUpperCase().slice(0, 3)
  }
  function getYear(month: TDateDraft) {
    return new Date(month).getFullYear()
  }

//@ts-ignore
  const formattedDate = (month) => `${getYear(month)} ${getMonthName(month)} `
  return (
    <>
      <Box
        sx={{
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8,
          position: 'sticky',
          top: 0,
          borderBottom: `1px solid black`,
          borderColor: 'divider',
          bgcolor: 'background.paper',
          zIndex: 99,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            p: 1,
            gap: 2,
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            position: 'sticky',
            top: 0,
            zIndex: 9,
          }}
        >
          {/*@ts-ignore*/}
          <MonthMultiSelect selectedMonths={columns} onChange={onMonthsChange} styleProp={{position: 'sticky',
            left: 0, margin: '10px'}} />
        </Box>

        <TableRow
          name={
            <div>
              <Button
                size="small"
                onClick={openOnClick}
                sx={{ ml: -1, px: 1, py: 0 }}
              >
                <Typography variant="overline" color="text.secondary" noWrap>
                  {t('categories', {
                    ns: 'budgets',
                    context: isAllShown ? 'all' : '',
                  })}
                </Typography>
                <ChevronDownIcon />
              </Button>
            </div>
          }

          columns={columns.map(col => <ColumnTitle name={formattedDate(col)} key={col}/>)}
        />
      </Box>

      <TableMenu1 />
    </>
  )
}
