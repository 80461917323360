'use client';
import { FormControl } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useCallback } from 'react';

const testData1 = {
  "direction 1": {
    "subDirection 1.1": {},
    "subDirection 1.2": {},
    "subDirection 1.3": {},
  },
  "direction 2": {
    "subDirection 2.1": {},
    "subDirection 2.2": {},
    "subDirection 2.3": {
      "subSubDirection 2.3.1": {},
      "subSubDirection 2.3.2": {}
    }
  },
};

function flattenObject(obj: Record<string, any>, parentKey = '', result: Record<string, any> = {}): Record<string, any> {
  Object.keys(obj).forEach(key => {
    const newKey = parentKey ? `${parentKey}..${key}` : key;
    if (typeof obj[key] === 'object' && obj[key] !== null && Object.keys(obj[key]).length > 0) {
      result[newKey] = obj[key] || {};
      flattenObject(obj[key], newKey, result);
    } else {
      result[newKey] = obj[key] || {};
    }
  });
  return result;
}

export function MultilevelListbox({ items = testData1, label, value, onChange }: MultilevelListboxProps) {
  const handleChangeListbox = useCallback((event: SelectChangeEvent) => {
    onChange(event.target.value);
  }, [onChange]);
  const flattenedItems = Object.keys(flattenObject(items));
  const menuItems = flattenedItems.map(menuItem =>
    <MenuItem
      key={menuItem}
      value={menuItem}
    >
      {
        // menuItem.replaceAll("..","↳")
        "   ".repeat(menuItem.split('..').length - 1) +
        // "---".repeat(menuItem.split('..').length - 1) +
        // ((menuItem.split('..').length > 1) ? ">" : "") +
        menuItem.split('..').pop()
      }
    </MenuItem>
  );

  return (
    <>
      <FormControl>
        <InputLabel id="listBoxLabel">{label}</InputLabel>
        <Select labelId="listBoxLabel" value={value} onChange={handleChangeListbox}>
          {menuItems}
        </Select>
      </FormControl>
    </>
  );
}

export type MultilevelListboxProps = {
  items?: Record<string, any>;
  label: string;
  value: string;
  onChange: (value: string) => void;
}
