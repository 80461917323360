import React, {FC, memo, useCallback, useEffect, useMemo, useState} from 'react'
import { isEqual } from 'lodash'
import { Paper } from '@mui/material'
import {TISOMonth, TTransaction} from '6-shared/types'
import { useToggle } from '6-shared/hooks/useToggle'

import { useAppSelector } from 'store/index'
import { envelopeModel, TEnvelopeId } from '5-entities/envelope'

import { Parent } from './Parent'
import { Row } from './Row'
import { Header } from './Header'
import { useExpandEnvelopes } from './models/useExpandEnvelopes'
import { Group } from './Group'
import {balances} from "../../../5-entities/envBalances";
import {NewGroup} from "../../Budgets/EnvelopeTable/NewGroup";
import {shallowEqual} from "react-redux";
import {
  useEnvRenderInfo
} from "../../Budgets/EnvelopeTable/models/envRenderInfo";
import {toISOMonth} from "../../../6-shared/helpers/date";

type TagTableProps = {
  className?: string
  onOpenDetails: (id: TEnvelopeId) => void
  onOpenOverview: () => void
  onShowTransactions: (list: TTransaction[]) => void
}

const CashFlowTable: FC<TagTableProps> = props => {
  const {
    className,
    onOpenDetails,
    onOpenOverview,
    onShowTransactions,
  } = props

  const structure = useAppSelector(envelopeModel.getEnvelopeStructure, isEqual)
  const { expanded, toggle, expandAll, collapseAll } = useExpandEnvelopes()
  const [showAll, toggleShowAll] = useToggle()
  const [reorderMode, toggleReorderMode] = useToggle()
  const operations = balances.useEnvDataWithIncome()


  const mergeData = (months: TISOMonth[]) => {
    const findDataById = (id:string) => {
      let result = { name: '', values: [] };
      const arrayOfOperations = Object.entries(operations)
      //@ts-ignore
      const filteredOperationsByDate = arrayOfOperations.filter(group => months.includes(group[0])).sort((a, b) => {
        //@ts-ignore
        return months.indexOf(a[0]) - months.indexOf(b[0]);
      });

      for (const [date, entry] of filteredOperationsByDate) {

        for (const [trId, transaction] of Object.entries(entry)) {

          if (trId === id) {
            result.name = transaction.name;
            // @ts-ignore
            result.values.push({
              date,
              selfActivity: transaction.selfActivity,
              totalActivity: transaction.totalActivity,
            });
          }
        }
      }
      return result;
    };
    //@ts-ignore
    const buildStructure = (nodes) => {
      //@ts-ignore
      return nodes.map(node => {
        //@ts-ignore
        const { name, values } = findDataById(node.id);

        return {
          ...node,
          name,
          values,
          children: buildStructure(node.children || [])
        };
      });
    };

    return buildStructure(structure);
  };

  const allMonths = balances.useMonthList()
  const defaultMonthsArray = useMemo(() => {
    const currentMonth = toISOMonth(new Date())
    // @ts-ignore
    const index = allMonths.indexOf(currentMonth)
    if (allMonths.length < 2) return [currentMonth]
    if (index === -1) return [allMonths.at(-2), allMonths.at(-1)]
    if (index === 0) return [currentMonth, allMonths.at(1)]
    if (index >= 1) return [currentMonth, allMonths.at(index-1)]
    else return allMonths

  },[allMonths])
  const [monthList, setMonthList] = useState(defaultMonthsArray)

  const onMonthsChange = useCallback((newMonthList: TISOMonth[]) => {
    setMonthList(newMonthList);
  }, [setMonthList])

  const months = useMemo(() => {
    return monthList?.length > 0 ? monthList.sort() : defaultMonthsArray.sort();
  }, [monthList, defaultMonthsArray])
  // eslint-disable-next-line react-hooks/rules-of-hooks
  //@ts-ignore
  const renderInfo = useEnvRenderInfo(months[0])
  //@ts-ignore

  const collectTransactionsListForDrawer = useCallback((id: TEnvelopeId, month: TISOMonth, isExact: boolean = false) => {
    const foundedMonth =  Object.entries(operations).find(period => period[0] === month)
    // @ts-ignore
    return foundedMonth[1]?.[id]?.[isExact ? 'selfTransactions' : 'totalTransactions']
  }, [operations])


  const onShowExactTransactions = useCallback(
    (id: TEnvelopeId, month: TISOMonth) => {
      const list = collectTransactionsListForDrawer(id, month, true)
      console.log(list)
      onShowTransactions(list)
    },
    [collectTransactionsListForDrawer, onShowTransactions]
  )

  const onShowAllTransactions = useCallback((id: TEnvelopeId, month: TISOMonth) => {
      const list = collectTransactionsListForDrawer(id, month)
    console.log(list)
      onShowTransactions(list)
    },
    [collectTransactionsListForDrawer, onShowTransactions]
  )



    //@ts-ignore
  const structureSource = mergeData(months)
  const renderGroups = structureSource
    //@ts-ignore
    .map((group, groupIdx) => {
      const parents = group.children
        //@ts-ignore
        .map(parent => {
          const { isDefaultVisible, showSelf } = renderInfo[parent.id]

          let renderChildren = parent.children
            //@ts-ignore
            .map((child, idx, arr) => (
              <Row
                key={'child' + child.id}
                id={child.id}
                values={child.values}
                valuesKey={"selfActivity"}
                isLastVisibleChild={idx === arr.length - 1}
                isDefaultVisible={true}
                isReordering={reorderMode}
                openTransactionsPopover={onShowExactTransactions}
                openDetails={onOpenDetails}
              />
            ))

          if (showSelf) {
            renderChildren = [
              <Row
                isSelf
                key={'self' + parent.id}
                id={parent.id}
                values={parent.values}
                valuesKey={"selfActivity"}
                isReordering={reorderMode}
                isDefaultVisible={true}
                openTransactionsPopover={onShowExactTransactions}
                openDetails={onOpenDetails}
              />,
              ...renderChildren,
            ]
          }

          const isExpanded =
            !!renderChildren.length && expanded.includes(parent.id)

          return (
            <Parent
              key={parent.id}
              id={parent.id}
              isExpanded={isExpanded}
              onExpandToggle={toggle}
              onExpandAll={expandAll}
              onCollapseAll={collapseAll}
              parent={
                <>
                  <div style={{paddingLeft: '24px'}}>
                    <Row
                  id={parent.id}
                  values={parent.values}
                  valuesKey={"totalActivity"}
                  isDefaultVisible={true}
                  isExpanded={isExpanded}
                  isReordering={reorderMode}
                  openDetails={onOpenDetails}
                  openTransactionsPopover={onShowAllTransactions}
                    />
                  </div>
                </>
              }
              children={renderChildren}
            />
          )
        })

      return {
        group,
        groupIdx,
        renderChildren: parents,
      }
    })
    //@ts-ignore
    .filter(data => data.renderChildren.length)
    //@ts-ignore
    .map((data, index, array) => {
      const { group, groupIdx, renderChildren } = data
//@ts-ignore
      const totalsByDate = group.children.reduce((acc, child) => {
        //@ts-ignore
        child.values.forEach(({ date, totalActivity }) => {
          if (!acc[date]) {
            acc[date] = {};
          }

          Object.keys(totalActivity).forEach(currency => {
            acc[date][currency] = (acc[date][currency] || 0) + totalActivity[currency];
          });
        });
        return acc;
      }, {});

      group.values = Object.keys(totalsByDate).map(date => ({
        date: date,
        totalActivity: totalsByDate[date]
      }));
      const prevVisibleIdx = array[index - 1]?.groupIdx
      const nextVisibleIdx = array[index + 1]?.groupIdx

      return (
        <Group
          key={group.id}
          name={group.id}
          values={group?.values}
          groupIdx={groupIdx}
          prevIdx={prevVisibleIdx}
          nextIdx={nextVisibleIdx}
          isReordering={reorderMode}
        >
          {renderChildren}
        </Group>
      )
    })

  return (
    <Paper className={className} sx={{position: 'relative', pb: 1, minWidth: `100%`,
      width: `${months.length * 80 + 320}px`}}>
      {months.length && (
        <Header
          //@ts-ignore
          columns={months}
          isAllShown={showAll}
          isReordering={reorderMode}
          onShowAllToggle={toggleShowAll}
          onReorderModeToggle={toggleReorderMode}
          onOpenOverview={onOpenOverview}
          onMonthsChange={onMonthsChange}
        />
      )}
      <NewGroup visible={reorderMode} />
      {months.length && (renderGroups)}
      {/*<Footer months={months}/>*/}
    </Paper>
  )
}

export const EnvelopeTable = memo(
  (props: TagTableProps) => (
      <CashFlowTable {...props} />
  ),
  shallowEqual
)
