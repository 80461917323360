import React, {FC, useRef, useState} from 'react'
import { ButtonBase, IconButton, Typography, Box, Collapse } from '@mui/material'
import {
  AddIcon,
  ArrowDownwardIcon, ArrowUpwardIcon,
  ChevronRightIcon
} from '6-shared/ui/Icons'
import { useFloatingInput } from '6-shared/ui/FloatingInput'

import { useAppDispatch, useAppSelector } from 'store/index'
import { renameGroup } from '4-features/envelope/renameGroup'
import { TableRow } from './shared/shared'
import {TFxAmount, TISOMonth} from '6-shared/types'
import { displayCurrency } from '5-entities/currency/displayCurrency'
import { Amount } from '6-shared/ui/Amount'
import { useTranslation } from 'react-i18next'
import {Tooltip} from "../../../6-shared/ui/Tooltip";
import {moveGroup} from "../../../4-features/envelope/moveGroup";
import {createEnvelope} from "../../../4-features/envelope/createEnvelope";

type TGroupProps = {
  name: string
  children?: React.ReactNode[]
  values: any[]
  groupIdx: number
  prevIdx?: number
  nextIdx?: number
  isReordering: boolean
}
export const Group: FC<TGroupProps> = ({
  name,
  groupIdx,
  prevIdx,
  nextIdx,
  isReordering,
  children,
  values
}) => {
  const { t } = useTranslation('budgets')
  const dispatch = useAppDispatch()
  const ref = useRef()

  const floating = useFloatingInput(ref, val =>
    dispatch(renameGroup(name, val))
  )
  const [isExpanded, setIsExpanded] = useState(true)
  const expandAction = (
    <IconButton
      size="small"
      sx={{
        transform: isExpanded ? 'rotate(90deg)' : 'rotate(0deg)',
        transition: '.3s',
        zIndex: 1,
      }}
      onClick={e => setIsExpanded(!isExpanded)}
    >
      <ChevronRightIcon fontSize="inherit" />
    </IconButton>
  )

  const Actions = (
    <>
      {nextIdx !== undefined && (
        <Tooltip title={t('moveDown')}>
          <IconButton onClick={() => dispatch(moveGroup(groupIdx, nextIdx))}>
            <ArrowDownwardIcon />
          </IconButton>
        </Tooltip>
      )}

      {prevIdx !== undefined && (
        <Tooltip title={t('moveUp')}>
          <IconButton onClick={() => dispatch(moveGroup(groupIdx, prevIdx))}>
            <ArrowUpwardIcon />
          </IconButton>
        </Tooltip>
      )}

      <Tooltip title={t('createEnvelope')}>
        <IconButton
          onClick={() =>
            dispatch(createEnvelope({ group: name, indexRaw: groupIdx }))
          }
        >
          <AddIcon />
        </IconButton>
      </Tooltip>
    </>
  )

  const NameCell = (
    <Box
      ref={ref}
      sx={{
        pb: 0,
        minWidth: 0,
        display: 'flex',
        flexDirection: 'row',
        alignContent: 'flex-end',
        justifyContent: 'flex-start',
        alignItems: 'center',
      }}
    >
      {expandAction}
      <ButtonBase
        sx={{ p: 1, ml: -1, minWidth: 0, flexShrink: 1 }}
        onClick={() => floating.open(name)}
      >
        <Typography variant="h6" sx={{ fontWeight: 900 }} noWrap>
          {name}
        </Typography>
      </ButtonBase>

      {isReordering && Actions}
    </Box>
  )

  const Sum: FC<{ value: number, month: TISOMonth }> = ({value, month}) => {
    const toDisplay = displayCurrency.useToDisplay(month)

    return (
      <Typography alignSelf={'baseline'} color="text.hint" align="center" noWrap>
        {/*@ts-ignore*/}
        <Amount value={toDisplay(value)} decMode="ifOnly"/>
      </Typography>
    )
  }

  return (
    <>
      {floating.render()}
      <TableRow
        sx={{
          pt: 2,
          alignItems: 'baseline',
          borderBottom: `0.5px solid black`,
          borderColor: 'divider',
          '&:last-child': { border: 0 },
        }}
        name={NameCell}
        columns={
          values.map((activity, index) => (
            <Sum
              key={`${index}-112`}
              value={activity.totalActivity}
              month={activity.date}
              // onClick={e => openTransactionsPopover(activity.id)}
            />
          ))
        }
      />
      <Collapse in={isExpanded} unmountOnExit>
      {children}
      </Collapse>
    </>
  )
}

