import React from 'react'
import {Box, Collapse, IconButton} from '@mui/material'
import {ChevronRightIcon} from '6-shared/ui/Icons'
import {TEnvelopeId} from '5-entities/envelope'

type ParentProps = {
  id: TEnvelopeId
  isExpanded: boolean
  parent: React.ReactNode
  children?: React.ReactNode[]
  onExpandToggle: (id: TEnvelopeId) => void
  onExpandAll: () => void
  onCollapseAll: () => void
}

export const Parent = React.forwardRef<HTMLDivElement, ParentProps>(
  (props, ref) => {
    const {
      id,
      isExpanded,
      parent,
      children,
      onExpandToggle,
      onExpandAll,
      onCollapseAll,
      ...rest
    } = props

    const hasChildren = !!children && children.length > 0

    const handleExpand = (
      e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
      if (e.altKey) isExpanded ? onCollapseAll() : onExpandAll()
      else onExpandToggle(id)
    }

    return (
      <Box
        sx={{
          position: 'relative',
          background: 'background.paper',
          borderBottom: `0.5px solid black`,
          borderColor: 'divider',
          '&:last-child': {border: 0},
        }}
        ref={ref}
        {...rest}
      >
        {hasChildren && (
          <IconButton
            size="small"
            sx={{
              position: 'absolute',
              left: '0px',
              top: '3px',
              transform: isExpanded ? 'rotate(90deg)' : 'rotate(0deg)',
              transition: '.3s',
              zIndex: 1,
            }}
            onClick={handleExpand}
          >
            <ChevronRightIcon fontSize="inherit"/>
          </IconButton>
          )}
        {parent}
        {hasChildren && (
          <Collapse in={isExpanded} unmountOnExit>
            <Box pb={1}>{children}</Box>
          </Collapse>
        )}
      </Box>
    )
  }
)
