import {useTranslation} from "react-i18next";
import {
  useTransactionPreview
} from "../../../../3-widgets/global/TransactionPreviewDrawer";
import React, {useCallback} from "react";
import {Box, Drawer, IconButton, Typography} from "@mui/material";
import {Tooltip} from "../../../../6-shared/ui/Tooltip";
import {CloseIcon} from "../../../../6-shared/ui/Icons";
import {
  TransactionList
} from "../../../../3-widgets/transaction/TransactionList";


export const PNLTransactionListDrawer = (props: { transactions: any; title?: any; onClose: any; open: any; }) => {
  const {
    transactions,
    title,
    onClose,
    open,
  } = props
  const { t } = useTranslation('common')
  const trPreview = useTransactionPreview()

  const showTransaction = useCallback(
    (id: string) => {
      trPreview.open({
        id,
        onOpenOther: (id: string) => {
          // trPreview.close()
          showTransaction(id)
        },
        onSelectSimilar: () => {
          // TODO: implement
        },
      })
    },
    [trPreview]
  )

  const drawerWidth = { xs: '100vw', sm: '40vw' }
  const contentSx = {
    width: drawerWidth,
    transition: 'width 0.3s ease-in-out',
    [`& .MuiDrawer-paper`]: {
      width: drawerWidth,
      transition: 'width 0.3s ease-in-out',
    },
  };

  return (
    <Drawer
      anchor="right"
      onClose={onClose}
      open={open}
      sx={contentSx}
      keepMounted={false}
    >
      <Box height="100vh" display="flex" flexDirection="column" minWidth={320}>
        <Box py={1} px={3} display="flex" alignItems="center">
          <Box flexGrow={1}>
            <Typography variant="h6" noWrap>
              {title || t('transactions')}
            </Typography>
          </Box>

          <Tooltip title={t('close')}>
            <IconButton edge="end" onClick={onClose} children={<CloseIcon />} />
          </Tooltip>
        </Box>

        <TransactionList
          transactions={transactions          }
          onTrOpen={showTransaction}
          hideFilter
          sx={{ flex: '1 1 auto' }}
        />
      </Box>
    </Drawer>
  )
}
