import React, {FC, ReactElement, useCallback, useState} from 'react'
import {Helmet} from 'react-helmet'
import {Box, Theme, useMediaQuery} from '@mui/material'
import {TEnvelopeId} from '5-entities/envelope'

import {TrFilterMode} from '5-entities/envBalances'

import {useTrDrawer} from './useTrDrawer'
// import {CashFlowTable} from './EnvelopeTable'
import {PnlReport} from './EnvelopeTable'
import { DnDContext } from './DnD'
import {SideContent, useSideContent} from './SideContent'
import {TISOMonth, TTransaction} from "../../6-shared/types";
import {
  PNLTransactionListDrawer
} from "./EnvelopeTable/Sidebar/SidebarTransactions";

export default function PnlFunction() {
  const openSide = useSideContent()
  const showTransactions = useTrDrawer()
  const openOverview = useCallback(() => openSide('overview',), [openSide])

  const openTransactions = useCallback(
    (opts: { id: TEnvelopeId; isExact?: boolean, month: TISOMonth }) =>
      showTransactions({
        id: opts.id,
        month: opts.month,
        mode: TrFilterMode.Envelope,
        isExact: opts.isExact,
      }),
    [showTransactions]
  )

  const [isOpenTrList, setIsOpenTrList] = useState(false)
  const [transactions, setTransactions] = useState<TTransaction[]>([])

  const showPnlTransactionsCollection = (list: TTransaction[]) => {
    setTransactions(list)
    setIsOpenTrList(true)
  }

  const mainContent = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        height: 'fit-content',
        gap: 2,
        width: '100%',
      }}
    >
      <PnlReport
        onShowTransactions={showPnlTransactionsCollection}
        onOpenOverview={openOverview}
        onOpenDetails={openSide}
      />
      <PNLTransactionListDrawer
        open={isOpenTrList}
        onClose={() => setIsOpenTrList(false)}
        transactions={transactions}/>
    </Box>
  )

  return (
    <>
      <Helmet>
        <title>PNL Report</title>
        <meta name="description" content=""/>
        <link rel="canonical" href="https://zerro.app/budget"/>
      </Helmet>
      <DnDContext>
        <BudgetLayout mainContent={mainContent}/>
      </DnDContext>
    </>
  )
}

const sideWidth = 360
const sideSx = {
  width: sideWidth,
  flexShrink: 0,
  overflow: 'auto',
  bgcolor: 'background.paper',
}

const BudgetLayout: FC<{
  mainContent: ReactElement
}> = props => {
  const {mainContent} = props
  const isMD = useMediaQuery<Theme>(theme => theme.breakpoints.down('lg'))

  return (
    <Box sx={{display: 'flex', height: '100vh'}}>
      <Box
        sx={{
          display: 'flex',
          flexGrow: 1,
          justifyContent: 'center',
          height: '100%',
          width: 'calc(100vw - 720px)',
          overflow: 'auto',
          px: {xs: 0, md: 0},
          pb: 6,
        }}
      >
        {mainContent}
      </Box>

      {isMD ? (
        <SideContent width={sideWidth}/>
      ) : (
        <Box sx={sideSx}>
          <SideContent width={sideWidth} docked/>
        </Box>
      )}
    </Box>
  )
}

