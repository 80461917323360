import {Theme, useMediaQuery} from '@mui/material'
import {BoxProps, SxProps} from '@mui/system'
import {FC, ReactNode} from 'react'

export function useIsSmall() {
  return useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'))
}

const rowStyle: SxProps = {
  display: 'flex', gap: '8px', height: '48px',
  borderColor: 'var(--mui-palette-divider)'
}

export const TableRow: FC<
  BoxProps & {
  name: ReactNode
  columns: ReactNode[]
}
> = props => {
  const {name, columns, sx, ...rest} = props
  const isSmall= useIsSmall()
  return (
    <div
      style={{
      display: 'flex', gap: '8px', height: '48px',
      borderColor: 'var(--mui-palette-divider)'
    }}
    >
      {<span className={'name'} style={{
        textAlign: 'center',
        width: `${isSmall ? '200px' : '300px'}`,
        position: "sticky",
        backgroundColor: 'var(--mui-palette-background-paper)',
        borderColor: 'var(--mui-palette-divider)',
        zIndex: 2,
        left: 0
      }}>{name}
      </span>}
      <div style={{
        display: 'flex',
        justifyContent: 'flex-start',
        left: `${isSmall ? '220px' : '320px'}`,
        position: 'absolute'
      }}
      >
        {columns.map((col, index) => <span className={'col'}
                                           key={`${name}-${index}`}
                                           style={{
                                             width: '80px',
                                             textAlign: 'center',
                                             backgroundColor: 'var(--mui-palette-background-paper)',
                                             borderRight: '0.5px solid black',
                                             borderColor: 'var(--mui-palette-divider)'
                                           }}>
          {col}
        </span>)}
      </div>
    </div>
  )
}
