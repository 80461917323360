import React, {FC, ReactNode, useCallback, useMemo} from 'react'
import { useDroppable } from '@dnd-kit/core'
import { envelopeModel, TEnvelopeId } from '5-entities/envelope'

import { TableRow } from '../shared/shared'
import { NameCell } from './NameCell'
import { ActivityCell } from './ActivityCell'
import {TISOMonth} from "../../../../6-shared/types";
import {DragTypes} from "../../../Budgets/DnD";

type EnvelopeRowProps = {
  id: TEnvelopeId
  values: any[]
  isSelf?: boolean
  isLastVisibleChild?: boolean
  isExpanded?: boolean
  isReordering: boolean
  openDetails: (id: TEnvelopeId) => void
  openTransactionsPopover: (id: TEnvelopeId, month: TISOMonth) => void
  isDefaultVisible: boolean
  valuesKey: 'totalActivity' | 'selfActivity'
}

export const Row: FC<EnvelopeRowProps> = props => {
  const {
    id,
    isSelf,
    isReordering,
    openTransactionsPopover,
    openDetails,
    values,
    isLastVisibleChild,
    isExpanded,
    isDefaultVisible,
    valuesKey
  } = props

  const envelope = envelopeModel.useEnvelopes()[id]

  const isChild = !!envelope.parent || !!isSelf

  const handleNameClick = useCallback(() => openDetails(id), [id, openDetails])

  return  (
    <Droppable
      id={id}
      isChild={isChild}
      isLastVisibleChild={!!isLastVisibleChild}
      isExpanded={!!isExpanded}
    >
      <TableRow
        sx={{
          paddingLeft: "20px",
          position: 'relative',
          cursor: 'pointer',
          '&:hover': { bgcolor: 'action.hover', transition: '0.1s' },
          '&:active': { bgcolor: 'action.focus', transition: '0.1s' },
          '&:hover .addGoal': { opacity: 1, transition: '.3s' },
          '&:not(:hover) .addGoal': { opacity: 0 },
          '& > *': { py: isChild ? 0.5 : 1 },
        }}
        name={
          <NameCell
            onClick={handleNameClick}
            envelope={envelope}
            isChild={isChild}
            isSelf={isSelf}
            isDefaultVisible={isDefaultVisible}
            isReordering={isReordering}
          />
        }
        columns={
          values.map((activity, index) => (
            <ActivityCell
              key={activity.date}
              value={activity[valuesKey]}
              month={activity.date}
              onClick={e => openTransactionsPopover(id, activity.date)}
            />
          ))
        }
      />
    </Droppable>
  )
}

const Droppable: FC<{
  id: TEnvelopeId
  isChild: boolean
  isLastVisibleChild: boolean
  isExpanded: boolean
  children: ReactNode
}> = props => {
  const { id, isChild, isLastVisibleChild, isExpanded, children } = props
  const { setNodeRef } = useDroppable({
    id: 'envelope-drop' + id + isChild,
    data: { type: DragTypes.envelope, id, isLastVisibleChild, isExpanded },
  })

  return <div ref={setNodeRef}>{children}</div>
}
