import React, {
  FC,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react'
import {ListChildComponentProps, VariableSizeList} from 'react-window'
import {
  StaticDatePicker,
  StaticDatePickerProps,
} from '@mui/x-date-pickers/StaticDatePicker'
import AutoSizer, {VerticalSize} from 'react-virtualized-auto-sizer'
import {ListSubheader, Theme, useMediaQuery} from '@mui/material'
import {formatDate, parseDate, toISODate} from '6-shared/helpers/date'
import {TDateDraft, TISODate} from '6-shared/types'
import {SmartDialog} from '6-shared/ui/SmartDialog'
import {registerPopover} from '6-shared/historyPopovers'
import {
  useTransactionPreviewProps
} from "../../global/TransactionPreviewDrawer";

type GroupNode = {
  date: TISODate
  transactions: JSX.Element[]
}
const HEADER_HEIGHT = 32
const TRANSACTION_HEIGHT = 118
const LAST_TRANSACTION_BOTTOM_MARGIN = 12

const useIsMobile = () => {
  return useMediaQuery<Theme>((theme) => theme.breakpoints.down('md'));
};

const findDateIndex = (groups: GroupNode[], date: GroupNode['date']) => {
  for (let i = 0; i < groups.length; i++) {
    if (groups[i].date <= date) return i
  }
  return groups.length - 1
}

type GrouppedListProps = {
  groups: GroupNode[]
  initialDate?: TDateDraft
}

export const GrouppedList: FC<GrouppedListProps> = props => {
  const {groups, initialDate} = props
  const listRef = useRef<VariableSizeList>(null)
  const datePopover = dateDialog.useMethods()

  useEffect(() => {
    listRef?.current?.resetAfterIndex?.(0)
  }, [listRef, groups])

  const scrollToDate = useCallback(
    (date: TDateDraft) => {
      const idx = findDateIndex(groups, toISODate(date))
      listRef?.current?.scrollToItem(idx, 'start')
    },
    [groups]
  )

  const onDateClick = useCallback(
    (date: TISODate) => {
      datePopover.open({
        value: parseDate(date),
        minDate: parseDate(groups[groups.length - 1]?.date || 0),
        maxDate: parseDate(groups[0]?.date || 0),
        onChange: d => {
          datePopover.close()
          scrollToDate(d as TISODate)
        },
      })
    },
    [datePopover, groups, scrollToDate]
  )

  useEffect(() => {
    if (initialDate) setTimeout(() => scrollToDate(initialDate), 10)
  }, [initialDate, scrollToDate])

  return (
    <>
      <DateDialog/>
      <AutoSizer disableWidth>
        {(props: VerticalSize) => {
          const {height} = props
          if (!height) return null
          return (
            <VariableSizeList
              className="hidden-scroll"
              width="100%"
              height={height}
              ref={listRef}
              useIsScrolling
              itemCount={groups.length}
              itemData={{groups, onDateClick}}
              itemKey={i => groups[i].date}
              itemSize={i =>
                HEADER_HEIGHT +
                TRANSACTION_HEIGHT * groups[i].transactions.length +
                LAST_TRANSACTION_BOTTOM_MARGIN
              }
            >
              {Day}
            </VariableSizeList>
          );
        }}
      </AutoSizer>
    </>
  );
};

//
//
//
//
//
//

type DayData = {
  groups: GroupNode[]
  onDateClick: (date: TISODate) => void
}
const Day: FC<ListChildComponentProps<DayData>> = props => {
  const {index, style, data, isScrolling} = props
  const {groups, onDateClick} = data
  const renderContent = useRenderState(isScrolling)
  const date = groups[index].date
  const length = groups[index].transactions.length
  const {open: isOpened} = useTransactionPreviewProps().displayProps;
  const isMobile = useIsMobile();

  const groupStyle: React.CSSProperties = {
    willChange: 'padding',
    position: 'relative',
    marginLeft: 'auto',
    paddingRight: isOpened && !isMobile ? 348 : 0,
    transition: 'padding 0.3s cubic-bezier(0.25, 0.1, 0.25, 1)',
    marginRight: 'auto',
  };

  return (
    <div style={{...groupStyle, ...style}}>
      <ListSubheader
        onClick={() => onDateClick(date)}
        style={{
          borderBottom: '0.5px solid black',
          borderColor: 'var(--mui-palette-divider)',
        }}
      >
        {formatDate(date)}
      </ListSubheader>

      {renderContent ? (
        groups[index].transactions.map((transaction, idx) => (
          <div
            key={idx}
            style={{
              marginBottom: idx === length - 1 ? LAST_TRANSACTION_BOTTOM_MARGIN : 0,
            }}
          >
            {transaction}
          </div>
        ))
      ) : (
        <TrSkeleton height={length * TRANSACTION_HEIGHT}/>
      )}
    </div>
  );
};

const TrSkeleton = (props: { height: number }) => (
  <div
    style={{
      height: props.height,
      background:
        'radial-gradient(circle at 36px 36px, rgba(128,128,128,0.2) 20px,transparent 20px)',
      backgroundSize: '100% 72px',
    }}
  />
)

/** Used to delay rendering of complex components */
const useRenderState = (isScrolling?: boolean, delay = 300) => {
  const [renderContent, setRenderContent] = useState(!isScrolling)
  useEffect(() => {
    if (!isScrolling) setRenderContent(true)
    let timer = setTimeout(() => setRenderContent(true), delay)
    return () => clearTimeout(timer)
  }, [delay, isScrolling])
  return renderContent
}

//
//
//

type TDateDialogProps = {
  value: StaticDatePickerProps<TDateDraft>['value']
  minDate?: StaticDatePickerProps<TDateDraft>['minDate']
  maxDate?: StaticDatePickerProps<TDateDraft>['maxDate']
  onChange: StaticDatePickerProps<TDateDraft>['onChange']
}

const dateDialog = registerPopover<TDateDialogProps>('listSateDialog', {
  value: new Date(),
  onChange: () => {},
})

const DateDialog = () => {
  const {extraProps} = dateDialog.useProps()
  return (
    <SmartDialog elKey={dateDialog.key}>
      <StaticDatePicker
        {...extraProps}
        openTo="day"
        // renderInput={params => <TextField {...params} />}
      />
    </SmartDialog>
  )
}
