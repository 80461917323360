import React, {FC, memo, useCallback, useEffect, useMemo, useState} from 'react'
import {isEqual} from 'lodash'
import {Paper} from '@mui/material'
import {TISOMonth, TTransaction} from '6-shared/types'
import {useToggle} from '6-shared/hooks/useToggle'

import {useAppSelector} from 'store/index'
import {envelopeModel, TEnvelopeId} from '5-entities/envelope'

import {Parent} from './Parent'
import {Row} from './Row'
import {Header} from './Header'
import {useExpandEnvelopes} from './models/useExpandEnvelopes'
import {Group} from './Group'
import {balances} from "../../../5-entities/envBalances";
import {NewGroup} from "../../Budgets/EnvelopeTable/NewGroup";
import {shallowEqual} from "react-redux";
import {
  useEnvRenderInfo
} from "../../Budgets/EnvelopeTable/models/envRenderInfo";
import {toISOMonth} from "../../../6-shared/helpers/date";
import {
  collectStructureTransactionsIds
} from "../../../6-shared/api/rocketData/useRocketDBTools";
import {
  isoMonth,
  processTransactions
} from "../../../6-shared/api/rocketData/pnlDatesMerge";

import {instrumentModel} from "../../../5-entities/currency/instrument";

type TagTableProps = {
  className?: string
  onOpenDetails: (id: TEnvelopeId) => void
  onOpenOverview: () => void
  onShowTransactions: (list: TTransaction[]) => void
}

const PnlReportTable: FC<TagTableProps> = props => {
  const {
    className,
    onOpenDetails,
    onOpenOverview,
    onShowTransactions,
  } = props

  const structure = useAppSelector(envelopeModel.getEnvelopeStructure, isEqual)
  const {expanded, toggle, expandAll, collapseAll} = useExpandEnvelopes()
  const [showAll, toggleShowAll] = useToggle()
  const [reorderMode, toggleReorderMode] = useToggle()
  const operations = balances.useEnvDataWithIncome()

  const [populatedOps, setPopulatedOps] = useState([])
  const [pnlData, setPnlData] = useState([])
  const [pnlMonths, setPnlMonths] = useState([])

  const monthListBalances = balances.useMonthList()

  useEffect(() => {
    const getExtraData = async () => {
      const res = await collectStructureTransactionsIds(operations);
      //@ts-ignore
      setPnlData(res)
      //@ts-ignore
      setPnlMonths([...new Set(res.map(e => isoMonth(e.pl_date)))])
    };

    getExtraData();
  }, [operations]);

  useEffect(() => {
    const getExtraData = async () => {
      const newData = processTransactions(Object.entries(operations), pnlData)
      setPopulatedOps(newData);
    };

    getExtraData();
  }, [operations, pnlData]);

  // console.log('pnlMonths', pnlMonths)
  const allMonths = useMemo(() => [...new Set([...monthListBalances, ...pnlMonths].sort())], [pnlMonths]);


  const defaultMonthsArray = useMemo(() => {
    const currentMonth = toISOMonth(new Date())
    // @ts-ignore
    const index = allMonths.indexOf(currentMonth)
    if (allMonths.length < 2) return [currentMonth]
    if (index === -1) return [allMonths.at(-2), allMonths.at(-1)]
    if (index === 0) return [currentMonth, allMonths.at(1)]
    if (index >= 1) return [currentMonth, allMonths.at(index - 1)]
    else return allMonths

  }, [allMonths])
  const [monthList, setMonthList] = useState(defaultMonthsArray)

  const onMonthsChange = useCallback((newMonthList: TISOMonth[]) => {
    setMonthList(newMonthList);
  }, [setMonthList])

  const months = useMemo(() => {

    return monthList?.length > 0 ? monthList.sort() : defaultMonthsArray.sort();
  }, [monthList, defaultMonthsArray])
  const instruments = instrumentModel.useInstruments()


  function calculateActivity(transactions: { incomeInstrument: string | number; income: any; outcomeInstrument: string | number; outcome: number }[]) {
    const activity = {};

    transactions.forEach((transaction: { incomeInstrument: string | number; income: any; outcomeInstrument: string | number; outcome: number }) => {
      if (transaction.incomeInstrument) {
        // @ts-ignore
        const instrumentKey = instruments?.[transaction.incomeInstrument].shortTitle
        //@ts-ignore
        if (!activity[instrumentKey]) {
          //@ts-ignore
          activity[instrumentKey] = 0;
        }
        //@ts-ignore
        activity[instrumentKey] += transaction.income;
      }

      if (transaction.outcomeInstrument) {
        //@ts-ignore
        const instrumentKey = instruments?.[transaction.outcomeInstrument].shortTitle
        //@ts-ignore
        if (!activity[instrumentKey]) {
          //@ts-ignore
          activity[instrumentKey] = 0;
        }
        // @ts-ignore
        activity[instrumentKey] -= transaction.outcome;
      }
    });

    return activity;
  }



  function fillMissingMonths(results: any[], months: `${number}${number}${number}${number}-${number}${number}`[]) {
    const filledResults = [];

    for (const month of months) {
      const existingResult = results.find((result: { date: any }) => result.date === month);

      if (existingResult) {
        filledResults.push(existingResult);
      } else {
        filledResults.push({
          date: month,
          selfActivity: {},
          totalActivity: {}
        });
      }
    }

    return filledResults;
  }

  const mergeData = (months: TISOMonth[]) => {
    const findDataById = (id: string) => {
      let result = {name: '', values: []};
      const filteredOperationsByDate = populatedOps.filter(group => months.includes(group[0])).sort((a, b) => {
        return months.indexOf(a[0]) - months.indexOf(b[0]);
      });

      //@ts-ignore
      for (const [date, entry] of filteredOperationsByDate) {

        for (const [trId, transaction] of Object.entries(entry)) {

          if (trId === id) {
            //@ts-ignore
            result.name = transaction.name;
            // @ts-ignore
            result.values.push({
              date,
              //@ts-ignore
              selfActivity: calculateActivity(transaction.selfTransactions ?? []) || {},
              //@ts-ignore
              totalActivity: calculateActivity(transaction.totalTransactions ?? []) || {}
            });
          }
        }
      }
      if(result.name) {
        //@ts-ignore
        result.values = fillMissingMonths(result.values, months);
      }
      return result;


    };
    //@ts-ignore
    const buildStructure = (nodes) => {
      //@ts-ignore
      return nodes.map(node => {
        //@ts-ignore
        const {name, values} = findDataById(node.id);

        return {
          ...node,
          name,
          values,
          children: buildStructure(node.children || [])
        };
      });
    };

    return buildStructure(structure);
  };


  //@ts-ignore
  const renderInfo = useEnvRenderInfo(months[0])
  //@ts-ignore
  const structureSource = mergeData(months)

  const collectTransactionsListForDrawer = useCallback((id: TEnvelopeId, month: TISOMonth, isExact: boolean = false) => {
    const foundedMonth =  populatedOps.find(period => period[0] === month)
    // @ts-ignore
    return foundedMonth[1]?.[id]?.[isExact ? 'selfTransactions' : 'totalTransactions']
  }, [populatedOps])


  const onShowExactTransactions = useCallback(
    (id: TEnvelopeId, month: TISOMonth) => {
      const list = collectTransactionsListForDrawer(id, month, true)
      console.log(list)
      onShowTransactions(list)
    },
    [collectTransactionsListForDrawer, onShowTransactions]
  )

  const onShowAllTransactions = useCallback((id: TEnvelopeId, month: TISOMonth) => {
      const list = collectTransactionsListForDrawer(id, month)
      console.log(list)
      onShowTransactions(list)
    },
    [collectTransactionsListForDrawer, onShowTransactions]
  )

  const renderGroups = structureSource
    //@ts-ignore
    .map((group, groupIdx) => {
      const parents = group.children
        //@ts-ignore
        .map(parent => {
          const { isDefaultVisible, showSelf } = renderInfo[parent.id]

          let renderChildren = parent.children
            //@ts-ignore
            .map((child, idx, arr) => (
              <Row
                key={'child' + child.id}
                id={child.id}
                values={child.values}
                isLastVisibleChild={idx === arr.length - 1}
                isDefaultVisible={true}
                isReordering={reorderMode}
                openTransactionsPopover={onShowExactTransactions}
                openDetails={onOpenDetails}
                valuesKey={"selfActivity"}
              />
            ))

          if (showSelf) {
            renderChildren = [
              <Row
                isSelf
                valuesKey={"selfActivity"}
                key={'self' + parent.id}
                id={parent.id}
                values={parent.values}
                isReordering={reorderMode}
                isDefaultVisible={true}
                openTransactionsPopover={onShowExactTransactions}
                openDetails={onOpenDetails}
              />,
              ...renderChildren,
            ]
          }

          const isExpanded =
            !!renderChildren.length && expanded.includes(parent.id)

          return (
            <Parent
              key={parent.id}
              id={parent.id}
              isExpanded={isExpanded}
              onExpandToggle={toggle}
              onExpandAll={expandAll}
              onCollapseAll={collapseAll}
              parent={
                <>
                  <div style={{paddingLeft: '24px'}}>
                <Row
                  valuesKey={"totalActivity"}
                  id={parent.id}
                  values={parent.values}
                  isDefaultVisible={true}
                  isExpanded={isExpanded}
                  isReordering={reorderMode}
                  openDetails={onOpenDetails}
                  openTransactionsPopover={onShowAllTransactions}
                />
                  </div>
                </>
              }
              children={renderChildren}
            />
          )
        })

      return {
        group,
        groupIdx,
        renderChildren: parents,
      }
    })
    //@ts-ignore
    .filter(data => data.renderChildren.length)
    //@ts-ignore
    .map((data, index, array) => {
      const {group, groupIdx, renderChildren} = data
//@ts-ignore
      const totalsByDate = group.children.reduce((acc, child) => {
        //@ts-ignore
        child.values.forEach(({date, totalActivity}) => {
          if (!acc[date]) {
            acc[date] = {};
          }

          Object.keys(totalActivity).forEach(currency => {
            acc[date][currency] = (acc[date][currency] || 0) + totalActivity[currency];
          });
        });
        return acc;
      }, {});

      group.values = Object.keys(totalsByDate).map(date => ({
        date: date,
        totalActivity: totalsByDate[date]
      }));
      const prevVisibleIdx = array[index - 1]?.groupIdx
      const nextVisibleIdx = array[index + 1]?.groupIdx

      return (
        <Group
          key={group.id}
          name={group.id}
          values={group?.values}
          groupIdx={groupIdx}
          prevIdx={prevVisibleIdx}
          nextIdx={nextVisibleIdx}
          isReordering={reorderMode}
        >
          {renderChildren}
        </Group>
      )
    })

  // @ts-ignore
  return (
    <Paper className={className} sx={{position: 'relative', pb: 1, minWidth: `100%`,
      width: `${months.length * 80 + 320}px`}}>
      {months.length && (
        <Header
          //@ts-ignore
          allMonths={allMonths}
          columns={months}
          isAllShown={showAll}
          isReordering={reorderMode}
          onShowAllToggle={toggleShowAll}
          onReorderModeToggle={toggleReorderMode}
          onOpenOverview={onOpenOverview}
          onMonthsChange={onMonthsChange}
        />
      )}
      <NewGroup visible={reorderMode}/>
      {months.length && (renderGroups)}
      {/*<Footer months={months}/>*/}
    </Paper>
  )
}

export const PnlReport = memo(
  (props: TagTableProps) => (
    <PnlReportTable {...props} />
  ),
  shallowEqual
)
