import {TDateDraft, TTransaction, TTransactionId} from '6-shared/types'

import React, {FC} from 'react'
import styled from '@emotion/styled'
import {Theme, TypographyVariant} from '@mui/material'
import {TrType} from '5-entities/transaction'
import {Accounts, Amounts, Info, Symbol, Tags,} from './Transaction.Components'
import {useContextMenu} from '6-shared/hooks/useContextMenu'
import {IExtraTransactionData} from "3-widgets/transaction/TransactionList";
import {format} from "date-fns";
import {formatDate} from "../../../../6-shared/helpers/date";

export type TTransactionProps = {
  id: TTransactionId
  isChecked: boolean
  isOpened: boolean
  isInSelectionMode: boolean
  transaction: TTransaction & IExtraTransactionData
  type: TrType
  // Actions
  onOpen?: (id: TTransactionId) => void
  onToggle?: (id: TTransactionId) => void
  onPayeeClick?: (payee: string) => void
  onContextMenu?: (event: React.MouseEvent | React.TouchEvent) => void
}

export const Transaction: FC<TTransactionProps> = props => {
  const {
    id,
    isChecked,
    isOpened,
    isInSelectionMode,
    transaction,
    type,
    onOpen,
    onToggle,
    onPayeeClick: onFilterByPayee,
    onContextMenu,
  } = props

  const propsToPass = useContextMenu({
    onClick: () => onOpen?.(id),
    onContextMenu,
  })
  const tr = transaction
  const trType = type
  const {deleted} = tr
  function getMonthName() {
    return formatDate(tr.pl_date || tr.date, 'LLL').toUpperCase().slice(0, 3)
  }
  function getYear() {
    return new Date(tr.pl_date || tr.date).getFullYear()
  }

  const formattedDate =  `${getYear()} ${getMonthName()}`

  return (
    <Wrapper opened={isOpened} deleted={deleted} {...propsToPass}>
      <Symbol {...{tr, trType, isChecked, isInSelectionMode, onToggle}}
              style={{
                gridColumn: '1 / 2',
                gridRow: '1 / 5'}}
      />
      <Row>
        <Tags {...{tr, trType}} />
        <Amounts {...{tr, trType}} />
      </Row>
      <SecondaryRow>
        <Info {...{tr, trType, onFilterByPayee}} />
        <Accounts {...{tr, trType, onFilterByPayee}} />
      </SecondaryRow>
      <ThirdRow>
        <Comment>{tr.comment || ' '}</Comment>
        <DateDisplay>{formattedDate}</DateDisplay>
      </ThirdRow>
    </Wrapper>
  );

};

const Wrapper = styled.div<{ opened: boolean; deleted: boolean }>`
  user-select: none;
  -webkit-touch-callout: none;
  cursor: pointer;
  position: relative;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto auto auto;
  gap: 4px;
  height: 118px;
  padding: 8px;
  transition: 0.1s ease-in-out;
  text-decoration: ${p =>
    p.deleted ? 'line-through ' + p.theme.palette.error.main : 'none'};
  color: ${p => p.theme.palette.text.primary};

  ::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: ${p => p.theme.shape.borderRadius}px;
    background-color: ${p =>
      p.opened ? p.theme.palette.action.focus : 'transparent'};
    z-index: -1;
    transition: 0.1s ease-in-out;
  }

  :hover::before {
    ${p =>
      p.opened ? '' : `background-color: ${p.theme.palette.action.hover};`}
  }

  :active::before {
    background-color: ${p => p.theme.palette.action.focus};
  }
`;


const Row = styled.div`
  grid-column: 2 / 3;
  grid-row: 1 / 2;
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: space-between;
  color: ${p => p.theme.palette.text.primary};

  ${typography('body1')}
  > * + * {
    flex-shrink: 0;
  }
  > *:first-of-type {
    flex-grow: 1;
    min-width: min-content;
    max-width: 70%;
    position: relative;
    overflow: hidden;
    white-space: nowrap;
    margin-left: 8px;
    mask-image: linear-gradient(to left, transparent, black 40px);
    -webkit-mask-image: linear-gradient(to left, transparent, black 40px);
  }
`;

const SecondaryRow = styled(Row)`
  grid-row: 2 / 3;
  grid-column: 1 / 3;
  color: ${p => p.theme.palette.text.primary};
  ${typography('body2')};
  >*:first-of-type{
    margin-left: 0;
  }
  >*:last-of-type {
    color: ${p => p.theme.palette.text.secondary};
    overflow: hidden;
    text-overflow: ellipsis;
    flex-shrink: 1;
    margin-left: 8px;
  }

`;

const ThirdRow = styled(Row)`
  grid-row: 3 / 4;
  grid-column: 1 / 3;
  color: ${p => p.theme.palette.text.secondary};

  ${typography('body2')}
  > *:first-of-type{
    margin-left: 0;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    max-width: unset;
  }
  >*:last-of-type {
    color: ${p => p.theme.palette.text.primary};
    min-width: max-content;
  }
`;

const Comment = styled.span`
  flex: 1;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.2;
  max-height: 2.4em;
`;

const DateDisplay = styled.span`
  white-space: nowrap;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.43;
  height: 100%;
`;

function typography(key: TypographyVariant) {
  return (p: { theme: Theme; [x: string]: any }) => `
  font-family: ${p.theme.typography[key].fontFamily};
  font-size: ${p.theme.typography[key].fontSize};
  font-weight: ${p.theme.typography[key].fontWeight};
  line-height: ${p.theme.typography[key].lineHeight};
  `
}
